:global {
  .slick-current,
  .video {
    :local {
      .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: theme('borderRadius.md');

        @media (min-width: theme('screens.md')) {
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        }

        iframe {
          border-radius: theme('borderRadius.lg');
          width: 120%;
          height: 120%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: theme('zIndex.10');

          @media (min-width: theme('screens.md')) {
            width: 150%;
            height: 150%;
          }
        }
      }

      .button {
        z-index: theme('zIndex.30');

        &:hover div:first-child {
          opacity: 100 !important;
        }
      }
    }
  }

  .video {
    :local {
      .videoContainer {
        width: 100%;
        padding-bottom: 150%;
        position: relative;
        overflow: hidden;
        display: block !important;
        border-radius: theme('borderRadius.lg');

        @media (min-width: theme('screens.md')) {
          padding-bottom: 0;
          height: 600px;
        }
      }

      .video {
        border-radius: theme('borderRadius.md');
      }
    }
  }

  .option-video {
    :local {
      .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: theme('borderRadius.md');
        -webkit-mask-image: -webkit-radial-gradient(white, black);

        iframe {
          border-radius: theme('borderRadius.md');
          width: 150%;
          height: 150%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: theme('zIndex.10');
        }
      }

      .button {
        z-index: theme('zIndex.30');

        &:hover div:first-child {
          opacity: 100 !important;
        }
      }
    }
  }

  .option-video-no-expand {
    :local {
      .video {
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.fullScreen {
  iframe {
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: theme('zIndex.1051');
    top: 0;
    left: 0;
    transform: none;
  }
}
